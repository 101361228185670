import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

@Component({
  selector: 'app-property-slide',
  templateUrl: './property-slide.component.html',
  styleUrls: ['./property-slide.component.scss'],
  providers: [CurrencyPipe]  // Provide CurrencyPipe
})
export class PropertySlideComponent implements OnInit {
  @Output() payloadEvent = new EventEmitter<any>();
  hasMore: boolean = false;
  isExpanded: boolean = true;
  selectedMinValue: number | null = null;
  selectedMaxValue: number | null = null;
  selectedBudget: string = '';
  panelOpenState = false;
  userfilter: string[] = [];
  showAllBedrooms: boolean = false;
  selectedType: string[] = [];
  isFilterSidebarOpen = false;

  constructor(private cdr: ChangeDetectorRef, private currencyPipe: CurrencyPipe) {}

  propertyTypes = [
    { name: 'Residential', selected: false },
    { name: 'Commercial', selected: false },
    { name: 'Flat/Apartment', selected: false },
    { name: 'Independent House/Villa', selected: false },
    { name: 'Service Apartment', selected: false },
    { name: 'Farm House', selected: false },
    { name: 'Agricultural Land', selected: false },

  ];
  budgetOptions = [
    { value: 500000, label: '5 Lacs' },
    { value: 1000000, label: '10 Lacs' },
    { value: 1500000, label: '15 Lacs' },
    { value: 2500000, label: '25 Lacs' },
    { value: 5000000, label: '50 Lacs' },
    { value: 8000000, label: '80 Lacs' },
    { value: 10000000, label: '1 Cr' },
    { value: 100000000, label: '10+ Crores'}
  ];

  bedroomOptions = [
    { name: '1', value: '1', selected: false },
    { name: '2', value: '2', selected: false },
    { name: '3', value: '3', selected: false },
    { name: '4', value: '4', selected: false },
    { name: '5', value: '5', selected: false },
    { name: '6', value: '6', selected: false },
    { name: '7', value: '7', selected: false },
    { name: '8', value: '8', selected: false },
    { name: '9', value: '9', selected: false },
    { name: '10', value: '10', selected: false },
    { name: '10+', value: '10+', selected: false } 
  ];

  payload: any = {};

  toggleMore() {
    this.hasMore = !this.hasMore;
  }

  selectPropertyType(propertyType) {
    propertyType.selected = !propertyType.selected;
    if (propertyType.selected) {
      this.userfilter.push(propertyType.name);
      if (!this.selectedType.includes("type")) {
        this.selectedType.push("type");
      }
    } else {
      this.userfilter = this.userfilter.filter(name => name !== propertyType.name);
      if (this.userfilter.length === 0) {
        this.selectedType = this.selectedType.filter(type => type !== "type");
      }
    }

    this.updatePayload();
    this.cdr.detectChanges();
  }

  selectBedroom(bedroom) {
    console.log(bedroom);

    bedroom.selected = !bedroom.selected;
    if (bedroom.selected) {
      this.userfilter.push(bedroom.value);
      this.selectedType.push("noOfBadroom");

    } else {
      this.userfilter = this.userfilter.filter(name => name !== bedroom.name);
      this.selectedType = this.selectedType.filter(noOfBadroom => noOfBadroom !== "noOfBadroom");

    }
    this.updatePayload();
    this.cdr.detectChanges();
  }

  onBudgetChange() {
    if (this.selectedMinValue !== null && this.selectedMaxValue !== null) {
        const fromAsking = this.formatCurrency(this.selectedMinValue);
        const toAsking = this.formatCurrency(this.selectedMaxValue);
        this.selectedBudget = `${fromAsking} - ${toAsking}`;
        console.log('selectedBudget', this.selectedBudget);
        
        this.selectedType.push("asking");
        if (!this.userfilter.includes(this.selectedBudget)) {
            this.userfilter.push(this.selectedBudget);
        }
        this.updatePayload();
        this.cdr.detectChanges();
    }
}

formatCurrency(value: number): string {
    // Transform the value using CurrencyPipe
    const formattedValue = this.currencyPipe.transform(value, 'INR', 'symbol', '1.0-0');
    // Remove commas and the currency symbol
    return formattedValue?.replace(/[₹,]/g, '') ?? '';
}

  removeFilter(filter: string) {
    console.log(filter);

    this.userfilter = this.userfilter.filter(name => name !== filter);
    this.propertyTypes.forEach(pt => {
      if (pt.name === filter) pt.selected = false;
    });
    this.bedroomOptions.forEach(br => {
      if (br.name === filter) br.selected = false;
    });
    this.updatePayload();
    this.cdr.detectChanges();
  }

  removeBudget() {
    this.selectedBudget = '';
    this.selectedMinValue = null;
    this.selectedMaxValue = null;
    this.updatePayload();
    this.cdr.detectChanges();
  }

  clearSelection() {
    this.propertyTypes.forEach(pt => pt.selected = false);
    this.bedroomOptions.forEach(br => br.selected = false);
    this.removeBudget();
    this.userfilter = [];
    this.selectedType = [];
    this.updatePayload();
    this.cdr.detectChanges();
    this.isFilterSidebarOpen = false;
  }

  updatePayload() {
    this.payload = {
      filterName: this.selectedType,
      filterValue: this.userfilter,
    };

    if (this.selectedMinValue !== null && this.selectedMaxValue !== null) {
      this.payload.minBudget = this.selectedMinValue;
      this.payload.maxBudget = this.selectedMaxValue;
    }

    console.log(this.payload);
    this.payloadEvent.emit(this.payload);
  }

  applyFilter() {
    console.log('Final store data', this.payload);
    this.payloadEvent.emit(this.payload);
    this.isFilterSidebarOpen = false;
  }

  toggleShowAllBedrooms() {
    this.showAllBedrooms = !this.showAllBedrooms;
  }

  getVisibleBedrooms() {
    return this.showAllBedrooms ? this.bedroomOptions : this.bedroomOptions.slice(0, 5);
  }

  ngOnInit(): void {}
  toggleFilterSidebar() {
    this.isFilterSidebarOpen = !this.isFilterSidebarOpen;
  }

  

 

}
